html, body {
  background-color: #fff !important;
  color: #000 !important;
}

input[type="email"] {
  background-color: #fff;
  /* color: black */
}
input[type="email"]::placeholder {
  color: gray
}