@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Outfit:wght@800&display=swap');

[hidden] {display: none!important}
img {
  pointer-events: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}


.photo-grid-container {
  overflow: hidden;
  transition: all 0.5s ease;
}

.photo-grid-left {
  height: calc(100vh / 5);
  background-size: 1600px;
  background-repeat: repeat-x;
  background-position: center;
  width: 4800px;
  -webkit-animation: scroll-grid-left 180s linear infinite;
  animation: scroll-grid-left 180s linear infinite
}

.photo-grid-right {
  height: calc(100vh / 5);
  background-size: 1600px;
  background-repeat: repeat-x;
  background-position: center;
  width: 4800px;
  -webkit-animation: scroll-grid-right 180s linear infinite;
  animation: scroll-grid-right 180s linear infinite
}

@media (min-width: 768px) {
  .photo-grid-left, .photo-grid-right {
      /* height:500px; */
      background-size: 2420px;
      width: 7260px
  }
}

@-webkit-keyframes scroll-grid-left {
  0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: translate3d(-2420px, 0, 0);
      transform: translate3d(-2420px, 0, 0)
  }
}

@keyframes scroll-grid-left {
  0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: translate3d(-2420px, 0, 0);
      transform: translate3d(-2420px, 0, 0)
  }
}

@-webkit-keyframes scroll-grid-right {
  0% {
      -webkit-transform: translate3d(-2420px, 0, 0);
      transform: translate3d(-2420px, 0, 0)
    }
    
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }
}

@keyframes scroll-grid-right {
  0% {
    -webkit-transform: translate3d(-2420px, 0, 0);
      transform: translate3d(-2420px, 0, 0)
    }
    
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }
}

form {

  margin-bottom: 2em ;
}

#mc_embed_signup {
  margin: 1em 0;
}

#mc_embed_signup .newsletter_header {
  display: flex;
  justify-content: center;
  margin: 1em 0 ;
  width: 420px;
}

#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 0;
  margin: 0
}

#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.4em;
}

#mc_embed_signup input {
  border: 2px solid #000;
  -webkit-appearance: none;
}

#mc_embed_signup input[type=checkbox] {
  -webkit-appearance: checkbox;
}

#mc_embed_signup input[type=radio] {
  -webkit-appearance: radio;
}

#mc_embed_signup input:focus {
  border-color: #333;
}

#mc_embed_signups .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 0px;
  letter-spacing: .03em;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
  transition: all 0.23s ease-in-out 0s;
}

#mc_embed_signup .button:hover {
  background-color: #777;
}

#mc_embed_signup .small-meta {
  font-size: 11px;
}

#mc_embed_signup .nowrap {
  white-space: nowrap;
}

#mc_embed_signup .clear {
  clear: none;
  display: inline;
}

#mc_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}

#mc_embed_signup input.email {
  font-size: 15px;
  display: block;
  padding: 0.6em;
  /*margin:0 4% 10px 0*/
  ;
  min-height: 32px;
  width: 40%;
  min-width: 130px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#mc_embed_signup input.button {
  display: block;
  width: 20%;
  margin: 0 0 10px 0;
  min-width: 90px;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em .5em 0em .5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}

#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em .5em .5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}

#mc_embed_signup #mce-error-response {
  display: none;
}

#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}

#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: .5em 0;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
}
