.container {
    background-color: #ff4d4d;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.logo {
    height: 20vmin;
    pointer-events: none;
}

.title {
    margin-top: 100px;
}